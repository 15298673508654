import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { Redirect, Route, Switch } from "react-router";
import "./App.css";
//import Dashboard from "./components/admin/Dashboard";
import FooterMenu from "./layout/FooterMenu";
import HeaderMenu from "./layout/HeaderMenu";
import NewDashboard from "./components/admin/Notifications_3_Dashboard";

const App = () => {
  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <HeaderMenu />
      <div className="page-wrapper">
        <Switch>
          <Route path='/'>
              <NewDashboard />
          </Route>          
          <Redirect to="/" />
        </Switch>
      </div> 
      <FooterMenu />
    </MsalAuthenticationTemplate>    
  )
};


export default App;
