import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "../components/admin/types/Customer";

export interface ICustomerState {
  allCustomers: Array<Customer>;
  filteredCustomers: Array<Customer>;
  isLoading: boolean;
  notifications: Array<any>;
  activeTab: string;
}

const initialState: ICustomerState = {
  allCustomers: [],
  filteredCustomers: [],
  isLoading: true,
  notifications: [],
  activeTab: "1",
};

const CustomersSlice = createSlice({
  name: "CustomerDetails",
  initialState: initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction) => {
      state.activeTab = "2";
    },
    resetActiveTab: (state) => {
      state.activeTab = "1";
    },
    setIsLoading: (state, action: PayloadAction) => {
      state.isLoading = false;
    },
    resetIsLoading: (state) => {
      state.isLoading = true;
    },
    setAllCustomers: (state, action: PayloadAction<Array<Customer>>) => {
      state.allCustomers = action.payload;
      state.filteredCustomers = action.payload;
    },
    setFilteredCustomers: (state, action: PayloadAction<Array<Customer>>) => {
      state.filteredCustomers = action.payload;
    },
    resetFilteredCustomers: (state) => {
      state.filteredCustomers = state.allCustomers;
    },
    setNotifications: (state, action: PayloadAction<Array<any>>) => {
      state.notifications = action.payload;
    },
  },
});

const CustomerReducer = CustomersSlice.reducer;
const CustomerActions = CustomersSlice.actions;
export { CustomerReducer, CustomerActions };
