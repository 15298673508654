import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../redux/createStore'
import { CustomerActions } from '../redux/Customer';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { sortKey, warningText } from '../components/admin/common/msgText';
import { popupNotification } from '../components/admin/services/CustomerServices';
import { TemplateActions } from '../redux/Template';
/**
 * @description custom hook which replaces old way of service calls. TODO: this should be removed
 * @returns 
 */
const useDashboard = () => {
    const dispatch = useDispatch();
    //calling auth hook to get idToken for sending in api for security
    const { userProfile } = useSelector((state: RootState) => state.Auth);
    const { idToken } = userProfile
    // redux state values
    const { allCustomers, filteredCustomers, isLoading, activeTab } = useSelector((state: RootState) => state.Customer);
    /**
     * @description fetches all users data fom server and store in redux state
     * @param filterText search string
     */
    const getCustomers = async (token: any) => {
        axios.get(`${process.env.REACT_APP_API}/customers`, {
            headers: {
                "APIkey": token
            }
        }).then((response) => {
            const result = response.data;
            const orderData = result.Items.length > 0 ? sortByProperty(result.Items, "timestamp").reverse() : result.Items;
            dispatch(CustomerActions.setIsLoading())
            dispatch(CustomerActions.setAllCustomers(orderData));
            dispatch(CustomerActions.setFilteredCustomers(orderData));
        }).catch((e: any) => {
            throw new Error(e)
        });
    }

    /**
   * @description fetches customers and analytics data for searched users
   */
    const searchCustomers = async (orderID: string) => {
        //dispatchMultiple(popupTitle.clickCount, dispatch)
        //check if empty or not              
        if (orderID === '') {
            // if user clear the search field the we need to display total data            
            dispatch(CustomerActions.resetFilteredCustomers());
        } else {
            if (!orderID.match(/^[0-9]+$/)) {
                //notify error  
                popupNotification(warningText.invalidSearchTitle, warningText.invalidSearchText, () => { }, 'popup')
            } else {
                dispatch(CustomerActions.resetIsLoading());
                await axios.get(`${process.env.REACT_APP_API}/orders`, {
                    params: {
                        search: orderID
                    },
                    headers: {
                        "APIkey": idToken
                    }
                }).then((response) => {
                    const { data } = response;
                    const { Items } = data
                    dispatch(CustomerActions.setIsLoading());
                    if (Items.length === 0) {
                        dispatch(CustomerActions.setFilteredCustomers(Items));
                        popupNotification(warningText.invalidSearchTitle, warningText.dataNotFoundText, () => { }, 'popup')
                    } else {
                        const orderData = Items.length > 0 ? sortByProperty(Items, sortKey) : Items;
                        dispatch(CustomerActions.setFilteredCustomers(orderData));
                    }
                }).catch((e: any) => {
                    throw new Error(e)
                });
            }

        }
    }
    /**
    * @description takes the response data and sorts the response based on the key passed
    * @returns sorted array of object in ascending order
    */
    const sortByProperty = (dataarray: any, key: any) => {
        if (dataarray.length > 0) {
            return dataarray.sort((prev: any, next: any) => {
                var firstObj = prev[key]; var secondObj = next[key];
                return ((firstObj < secondObj) ? -1 : ((firstObj > secondObj) ? 1 : 0));
            });
        }
    }
    /**
    * @description custom pagination for antd table --also handles ie and safari browser pagination design issue
    * @params takes the current page number , type of button click and the elements event state
    */
    const renderPagination = (current: any, type: any, originalElement: any) => {
        if (type === 'prev') {
            return <a className="table-prev" ><MdKeyboardArrowLeft /></a>;
        } if (type === 'next') {
            return <a className="table-next"><MdKeyboardArrowRight /></a>;
        }
        return originalElement;

    }


    /**
  * @description fetches Notifications and store in redux state
  */
    const getNotifications = async (lastEvalKey: string, minDate: string, maxDate: string, token: any) => {
        dispatch(TemplateActions.setLoadingUI(true));
        axios.get(`${process.env.REACT_APP_API}/get-template-notifications?lastEvaluatedKey=${lastEvalKey}&minDate=${minDate}&maxDate=${maxDate}`, {
            headers: {
                "APIkey": token
            }
        }).then((response) => {
            const result = response.data;
            if (result) {
                dispatch(TemplateActions.setNotifications(result));
            } else {
                dispatch(TemplateActions.setNotifications({}));
            }

            dispatch(TemplateActions.setLoadingUI(false));
        }).catch((e: any) => {
            dispatch(TemplateActions.setLoadingUI(false));
            dispatch(TemplateActions.setNotifications({}));
            dispatch(TemplateActions.setTableNoDataMsg("We are experiencing some issues, we will be back soon..."));
            throw new Error(e)
        });
    }

    /**
   * @description fetches search results and store in redux state
   */
    const getSearchResults = async (resultObj: any, token: any) => {
        dispatch(TemplateActions.setLoadingUI(true));
        dispatch(TemplateActions.setViewData({}));
        axios.post(`${process.env.REACT_APP_API}/search-notifications-by-param`, resultObj, {
            headers: {
                "APIkey": token
            }
        }).then((response) => {
            const result = response.data;

            if (result && result.items && result.items.length > 0) {
                dispatch(TemplateActions.setNotifications(result));
            } else {
                dispatch(TemplateActions.setNotifications([]));
            }
            dispatch(TemplateActions.setLoadingUI(false));
        }).catch((e: any) => {
            dispatch(TemplateActions.setLoadingUI(false));
            dispatch(TemplateActions.setNotifications([]));
            dispatch(TemplateActions.setTableNoDataMsg("We are experiencing some issues, we will be back soon..."));
            throw new Error(e)
        });
    }

    /**
    * @description fetches viewData results and store in redux state
    */
    const getViewDataResults = async (resultObj: any, token: any) => {

        dispatch(TemplateActions.setLoadingUI(true));
        dispatch(TemplateActions.setViewData({}));
        axios.post(`${process.env.REACT_APP_API}/templates-data`, resultObj, {
            headers: {
                "APIkey": token
            }
        }).then((response) => {
            const result = response.data;
            if (result && result.items && result.items.length > 0) {
                const parsedObject = JSON.parse(result.items[0].payload);
                dispatch(TemplateActions.setViewData(parsedObject.data ? parsedObject.data : parsedObject))
            } else {
                dispatch(TemplateActions.setViewData({}));
            }
            dispatch(TemplateActions.setLoadingUI(false));
        }).catch((e: any) => {
            dispatch(TemplateActions.setLoadingUI(false));
            dispatch(TemplateActions.setViewData({}));
            dispatch(TemplateActions.setTableNoDataMsg("We are experiencing some issues, we will be back soon..."));
            throw new Error(e)
        });
    }

    /**
   * @description fetches templatelist and store in redux state
   */
    const getTemplateLists = async (templateTxt: string,token:any) => {
        dispatch(TemplateActions.setLoadingUI(true));
        axios.get(`${process.env.REACT_APP_API}/get-template-notification-logs?search=${templateTxt}`, {
            headers: {
                "APIkey": token
            }
        }).then((response) => {
            const result = response.data;
            if (result && result.Items.length > 0) {
                dispatch(TemplateActions.setTemplateList(result.Items));
            } else {
                dispatch(TemplateActions.setTemplateList([]));
            }
            dispatch(TemplateActions.setLoadingUI(false));
        }).catch((e: any) => {
            dispatch(TemplateActions.setLoadingUI(false));
            dispatch(TemplateActions.setTableNoDataMsg("We are experiencing some issues, we will be back soon..."));
            throw new Error(e)
        });
    }

    /**
     * @description Create resend notification
     */
    const createResendNotification = async (payloadObject: any,token:any) => {
      
        dispatch(TemplateActions.setLoadingUI(true));
        axios.post(`${process.env.REACT_APP_API}/create-resend-notification`, payloadObject, {
            headers: {
                "APIkey": token
            }
        }).then((response) => {
            // const result = response.data.body;
            dispatch(TemplateActions.setLoadingUI(false));
            const notifObj = {
                message: "Success",
                description: "Notification has been sent successfully!"
            }
            dispatch(TemplateActions.setSendNotificationMsg(notifObj));
        }).catch((e: any) => {
            dispatch(TemplateActions.setLoadingUI(false));
            const notifObj = {
                message: "Error!",
                description: "Something went wrong, Please try after sometime!"
            }
            dispatch(TemplateActions.setSendNotificationMsg(notifObj));
            throw new Error(e)
        });
    }

    return {
        allCustomers,
        filteredCustomers,
        getCustomers,
        searchCustomers,
        isLoading,
        renderPagination,
        getNotifications,
        getSearchResults,
        getViewDataResults,
        getTemplateLists,
        createResendNotification,
        activeTab
    }
}

export default useDashboard;