import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITemplateState {
  isLoading: boolean;
  notifications: any;
  viewData: any;
  templateList: any;
  tableNoDataMsg: any;
  activeTab: string;
  loadingUI: boolean;
  sendNotificationMsg: any;
}

const initialState: ITemplateState = {
  isLoading: true,
  notifications: {},
  viewData: {},
  templateList: [],
  tableNoDataMsg: "No Data Found, Please try with different search criteria!",
  activeTab: "1",
  loadingUI: false,
  sendNotificationMsg: {},
};

const TemplateSlice = createSlice({
  name: "Templates",
  initialState: initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction) => {
      state.activeTab = "2";
    },
    resetActiveTab: (state) => {
      state.activeTab = "1";
    },
    setIsLoading: (state, action: PayloadAction) => {
      state.isLoading = false;
    },
    resetIsLoading: (state) => {
      state.isLoading = true;
    },
    setLoadingUI: (state, action: PayloadAction<any>) => {
      state.loadingUI = action.payload;
    },
    setNotifications: (state, action: PayloadAction<any>) => {
      state.notifications = action.payload;
    },
    setViewData: (state, action: PayloadAction<any>) => {
      state.viewData = action.payload;
    },
    setTemplateList: (state, action: PayloadAction<any>) => {
      state.templateList = action.payload;
    },
    setTableNoDataMsg: (state, action: PayloadAction<any>) => {
      state.tableNoDataMsg = action.payload;
    },
    setSendNotificationMsg: (state, action: PayloadAction<any>) => {
      state.sendNotificationMsg = action.payload;
    },
  },
});

const TemplateReducer = TemplateSlice.reducer;
const TemplateActions = TemplateSlice.actions;
export { TemplateReducer, TemplateActions };
