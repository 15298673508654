/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons';

import "./styles/dashboard.css";
import useDashboard from '../../hooks/useDashboard'
import Space from "antd/es/space";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/createStore";
import useTimer from "../../hooks/useTimer";
import { DatePicker, Empty, Input, Select, Spin, Tag, Tooltip, notification, Layout, Menu, theme } from 'antd';
import { Row, Col, Table, Typography, Button, Modal, message } from "antd";
import { ColumnType } from 'antd/es/table';
import { TemplateActions } from '../../redux/Template';
let currentTab = 1;
const { Header, Sider, Content } = Layout;

const items = [
  {
    label: 'Template',
    value: 'template_id',
  },
  {
    label: 'Notification Type',
    value: 'notification_channel',
  },
  {
    label: 'Notification Status',
    value: 'status',
  },
  {
    label: 'Notification Id',
    value: 'notification_id',
  },
  {
    label: 'Recipient',
    value: 'recipient',
  },
  {
    label: 'Notification Date',
    value: 'timestamp',
  }
];

const itemsNotificationType = [
  {
    label: 'SMS',
    value: 'text_message',
  },
  {
    label: 'Email',
    value: 'email',
  }
];


interface NumericInputProps {
  style: React.CSSProperties;
  value: string;
  onChange: (value: string) => void;
}

const formatNumber = (value: number) => new Intl.NumberFormat().format(value);


const getRowClassName = (record: any, index: number) => {
  // Add alternate row color
  return index % 2 === 0 ? 'table-row-even' : 'table-row-odd';
};


const NumericInput = (props: NumericInputProps) => {
  const { value, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(inputValue);
    }
  };

  // '.' at the end or only '-' in the input box.
  const handleBlur = () => {
    let valueTemp = value;
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    onChange(valueTemp.replace(/0*(\d+)/, '$1'));
  };

  const title = value ? (
    <span className="numeric-input-title">{value !== '-' ? formatNumber(Number(value)) : '-'}</span>
  ) : (
    ''
  );

  return (
    <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
      <Input
        {...props}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder=""
        maxLength={16}
      />
    </Tooltip>
  );
};

/**
 * @description  NewDashboard component is the container for all tables and tabs
 * @returns 
 */
const NewDashboard: FC = () => {

  // get redux state from customer
  const { getNotifications, getSearchResults, getViewDataResults, getTemplateLists, createResendNotification } = useDashboard();
  // get counter from use timer custom hook
  const { counter } = useTimer();
  //call auth hook to get idtoken and send it a param for getting all customer
  const { userProfile } = useSelector((state: RootState) => state.Auth);
  const { notifications, viewData, templateList, tableNoDataMsg, loadingUI, sendNotificationMsg } = useSelector(
    (state: RootState) => state.Templates
  );


  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const subtractDays = (date: any, days: any) => {
    date.setDate(date.getDate() - days);
    return date;
  }

  const getDisableState = (item: any) => {
    if (item && item.notificationSubscriptionStatus && currentTab === 2) {
      return item.notificationSubscriptionStatus.toLowerCase() === "opt_in" ? false : true;
    } else {
      return false;
    }
  }

  //Convert Date to ISOString DAte
  const getISOStringDate = (dt: string, dateType: string) => {
    const currentDate = new Date(dt);
    return currentDate.toISOString();
  }

  // //Dashboard table columns
  const columns: ColumnType<any>[] = [
    {
      title: 'Notification ID',
      dataIndex: 'notificationId',
      align: 'center',
      key: 'notificationId'
    },
    {
      title: 'Template Name',
      dataIndex: 'templateName',
      align: 'center',
      key: 'templateName',
      render: (text: any, item: any) => <Text style={{ fontSize: "12px", cursor: "pointer" }} onClick={() => (item && currentTab === 2) ? null : handleTemplateNameClick(item)}><a>{getFormattedTempName(item)}</a></Text>,
    },
    {
      title: 'Notification Type',
      dataIndex: 'notificationType',
      align: 'center',
      key: 'notificationType',
      render: (text: any) => <Tag color={getTag(text)} style={{ fontSize: "12px", color: "black" }} >{getFormattedTextMsg(text)}</Tag>,
    },

    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      key: 'status'
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      align: 'center',
      key: 'recipient',
      render: (text: any) => <Tooltip title={text}>
        <span style={{ display: 'inline-block', maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {text}
        </span>
      </Tooltip>,
    },
    {
      title: 'Notification Date',
      dataIndex: 'notificationDate',
      align: 'center',
      key: 'notificationDate',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      align: 'center',
      key: 'data',
      render: (text: any, item: any) => <Button type="primary" shape="round" size="middle" onClick={() => viewDataClickHandle(item, false)}>{text}</Button>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      className: 'template-name-column',
      render: (text: any, item: any) => <Button disabled={getDisableState(item)} style={{ backgroundColor: "orange" }} type="primary" shape="round" size="middle" onClick={() => { setResendClick(true); viewDataClickHandle(item, true) }}>{text}</Button>,
    },
  ];

  //Template list table columns inside modal
  const templateListColumns: ColumnType<any>[] = [
    {
      title: 'Notification ID',
      dataIndex: 'notificationId',
      align: 'center',
      key: 'notificationId',
    },
    {
      title: 'Template Name',
      dataIndex: 'templateName',
      align: 'center',
      key: 'templateName',
      render: (text: any, item: any) => <Text style={{ fontSize: "12px", cursor: "pointer" }} onClick={() => handleTemplateNameClick(item)}>{getFormattedTempName(item)}</Text>,
    },
    {
      title: 'Notification Type',
      dataIndex: 'notificationType',
      align: 'center',
      key: 'notificationType',
      render: (text: any) => <Tag color={getTag(text)} style={{ fontSize: "12px", color: "black" }} >{getFormattedTextMsg(text)}</Tag>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      key: 'status',
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      align: 'center',
      key: 'recipient',
      render: (text: any) => <Tooltip title={text}>
        <span style={{ display: 'inline-block', maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {text}
        </span>
      </Tooltip>,
    },
    {
      title: 'Notification Date',
      dataIndex: 'notificationDate',
      align: 'center',
      key: 'notificationDate',
    },
  ]


  const token = userProfile.idToken;

  const dispatch = useDispatch()

  const [selectedFilter, setSelectedFilter] = useState("Filter Attribute");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>("");
  const [templateArr, setTemplateArr] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchVal, setSearchVal] = useState("");
  const [minDate, setMinDate] = useState(getISOStringDate((subtractDays(new Date(), 30)).toString(), 'min'));
  const [maxDate, setMaxDate] = useState(getISOStringDate((new Date()).toString(), 'max'));
  const [searchClick, setSearchClick] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [templateListModalOpen, setTemplateListModalOpen] = useState(false);
  const [resendModalOpen, setResendModalOpen] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [searchModalTxt, setSearchModalTxt] = useState("");
  const [viewDataArr, setViewDataArr] = useState<any[]>([]);
  const [viewDataObj, setViewDataObj] = useState("");
  const [mtemplateList, setMtemplateList] = useState<any[]>([]);
  const [resendClick, setResendClick] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [selectedNotifType, setSelectedNotifType] = useState<any>("email");
  const [dateSelected, setDateSelected] = useState(false);
  const [finalNotificationColumns, setFinalNotificationColumns] = useState(columns);
  const [messageApi, contextHolder] = message.useMessage();
  const [api, contextHolderNotification] = notification.useNotification();
  const [isNavigationChanged, setIsNavigationChanged] = useState(false);


  const getFormattedTextMsg = (msg: string) => {
    let formatedText = msg.split('_');
    if (formatedText && formatedText.length > 1) {
      return `${formatedText[0]} ${formatedText[1]}`;
    } else {
      return msg;
    }
  }

  const getFormattedTempName = (item: any) => {
    return `${item.templateName}_V${item.templateVersion}`
  }


  const getTag = (notification_type: string) => {
    try {
      notification_type = notification_type ? notification_type.toLowerCase() : notification_type;
      return notification_type === "email"
        ? "blue"
        : notification_type === "text_message"
          ? "green"
          : "violet"
    } catch (error) {
      console.error(error);
    }
  };

  // Get Template Api call
  useEffect(() => {
    if (token) {
      counter();
      getNotifications(lastEvaluatedKey, getISOStringDate((subtractDays(new Date(), 30)).toString(), 'min'), getISOStringDate((new Date()).toString(), 'max'), token);
    }
  }, [token]);





  //Date conversion to given format mm/dd/yyyy
  const converDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    });

    return formattedDate;
  }

  //Formatting Api response in ant table format
  const getFormatedTemplateArr = (arr: []) => {
    return arr.map((tempObject: any, i) => {
      let formatedObj = {
        key: i + 1,
        notificationId: tempObject.notification_id,
        templateName: tempObject.template_id,
        templateVersion: tempObject.version,
        notificationType: tempObject.notification_channel ? capitalizeFirstLetter(tempObject.notification_channel) : "",
        status: tempObject.status ? capitalizeFirstLetter(tempObject.status) : "",
        recipient: tempObject.recipient ? tempObject.recipient : "",
        tempVersionValue: tempObject.templateid_version ? tempObject.templateid_version : "",
        notificationDate: converDate(tempObject.timestamp),
        notificationSubscriptionStatus: tempObject.notification_subscription_status ? tempObject.notification_subscription_status : "",
        data: "View Data",
        action: "Resend",
      }

      return formatedObj
    })
  }

  //Formatting Api response in ant table format, Template list table columns inside modal
  const getFormatedTemplateList = (arr: []) => {
    return arr.map((tempObject: any, i) => {
      let formatedObj = {
        key: i + 1,
        notificationId: tempObject.notification_id,
        templateName: tempObject.template_id,
        templateVersion: tempObject.version,
        recipient: tempObject.recipient ? tempObject.recipient : "",
        notificationDate: converDate(tempObject.timestamp),
        notificationType: tempObject.notification_channel ? capitalizeFirstLetter(tempObject.notification_channel) : "",
        notificationSubscriptionStatus: tempObject.notification_subscription_status ? tempObject.notification_subscription_status : "",
        status: tempObject.status ? capitalizeFirstLetter(tempObject.status) : "",
      }

      return formatedObj;
    })
  }

  //Handling click on view data button and open modal
  const viewDataClickHandle = (obj: any, isResendClick: boolean) => {
    setSelectedTemplate(obj);
    const payloadObj = {
      "column_name": "templateid_version",
      "value": obj.tempVersionValue ? obj.tempVersionValue : "",
      "notification_channel": obj.notificationType ? obj.notificationType.toLowerCase() : ""
    };
    isResendClick ? setResendModalOpen(true) : setModalOpen(true);

    getViewDataResults(payloadObj, token);

  }

  //Handling Response after getTemplate api call
  useEffect(() => {
    setFinalNotificationColumns(columns);
    if (Object.keys(notifications).length > 1 && notifications.items.length === 0) {
      dispatch(TemplateActions.setTableNoDataMsg("No data found for the last one week. Please use the search filter criteria to refine your results."))
    }

    if (Object.keys(notifications).length > 1) {

      setLastEvaluatedKey(notifications.lastEvaluatedKey);

      let finalNotificationsArr: any = [];

      //This useEffect has been called for 2 api response. We are using SearchClick
      // key to handle paggination inside Pagination function to call pagination api based to this SearchKeyClick key.
      if (searchClick) {
        if ((lastEvaluatedKey == null || lastEvaluatedKey === "")) {
          setSearchClick(false);
        }
      }
      finalNotificationsArr = [...templateArr, ...getFormatedTemplateArr(notifications.items)];
      setTemplateArr([]);
      setTemplateArr(finalNotificationsArr);

    } else {
      setTemplateArr([]);
    }
  }, [notifications]);

  //Send notification Response
  useEffect(() => {
    if (Object.keys(sendNotificationMsg).length > 1) {
      setSearchModal(true);
      setSearchModalTxt("modal");
    }
  }, [sendNotificationMsg]);


  //Handling Response after viewData api call
  useEffect(() => {
    if (Object.keys(viewData).length > 0) {

      if (resendClick) {
        setResendClick(false);
        let viewDataObjectArr: any[] = [];
        Object.keys(viewData).forEach(key => {
          const value = viewData[key];
          viewDataObjectArr.push({ [key]: value });
        });

        setViewDataArr(viewDataObjectArr);

      } else {
        const jsonString = JSON.stringify(viewData, null, 2);
        setViewDataObj(jsonString);
      }

    } else {
      setViewDataArr([]);
      setViewDataObj("");
    }

  }, [viewData])

  //Handling Response after templateList api call
  useEffect(() => {
    if (templateList.length > 0) {
      setMtemplateList([]);
      setMtemplateList(getFormatedTemplateList(templateList));
      setTemplateListModalOpen(true);
    } else {
      setMtemplateList([]);
    }

  }, [templateList])

  //Handle filter select
  const handleChange = (value: string) => {
    setSearchVal("");
    setSelectedFilter(value);
  };

  const handleChangeNotificationType = (value: any) => {
    setSelectedNotifType(value);
  }

  const handlePagination = (pagination: any) => {
    if (Object.keys(pagination).length > 0 && pagination.current && pagination.current !== currentPage) {
      if ((lastEvaluatedKey != null || lastEvaluatedKey !== "") && pagination.current > currentPage && pagination.current > totalPages) {
        setTotalPages(pagination.current);
        if (searchClick) {
          const val = selectedFilter === "notification_id" ? parseInt(searchVal) : searchVal;

          const payloadObj = {
            "column_name": selectedFilter,
            "minDate": minDate !== "" ? getISOStringDate(minDate, 'min') : "",
            "maxDate": maxDate !== "" ? getISOStringDate(maxDate, 'max') : "",
            "value": val,
            "notification_channel": selectedNotifType,
            "lastEvaluatedKey": lastEvaluatedKey
          };

          getSearchResults(payloadObj, token);

        } else {
          getNotifications(lastEvaluatedKey, getISOStringDate((subtractDays(new Date(), 30)).toString(), 'min'), getISOStringDate((new Date()).toString(), 'max'), token);
        }

      }
      setCurrentPage(pagination.current);
    }
  }

  //Reset Function to set Current page to 1 and empty the existing old data from Table items
  const resetDashboard = () => {
    setCurrentPage(1);
    setTotalPages(1);
    setTemplateArr([]);
  }

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const isSearchValid = (selected_filter: any, search_val: any) => {
    if (selected_filter === 'template_id' || selected_filter === 'notification_channel' || selected_filter === 'status' || selected_filter === 'notification_id' || selected_filter === 'recipient') {
      return searchVal === "" ? true : false;
    }
  }
  const handleSearch = () => {
    if (selectedFilter !== "" && selectedFilter !== 'Filter Attribute' && (searchVal !== "" || (minDate !== "" && maxDate !== "")) && !isSearchValid(selectedFilter, searchVal)) {

      if (selectedFilter === "timestamp" && (minDate === "" || maxDate === "" || !dateSelected)) {
        messageApi.open({
          type: 'warning',
          content: 'Please Enter Min and Max Date for search!',
        });

      } else {

        setSearchClick(true);
        resetDashboard();

        const val = selectedFilter === "notification_id" ? parseInt(searchVal) : searchVal;

        const payloadObj = {
          "column_name": selectedFilter,
          "minDate": minDate !== "" ? getISOStringDate(minDate, 'min') : "",
          "maxDate": maxDate !== "" ? getISOStringDate(maxDate, 'max') : "",
          "value": val,
          "notification_channel": selectedNotifType,
          "lastEvaluatedKey": ""
        };

        if (selectedFilter === "recipient" && !dateSelected) {
          setSearchModal(true);
          setSearchModalTxt("search");
        }

        getSearchResults(payloadObj, token);
        // setMinDate("");
        // setMaxDate("");
        // setSearchVal("");

      }
    } else {
      if (selectedFilter === "" || selectedFilter === 'Filter Attribute') {
        messageApi.open({
          type: 'warning',
          content: 'Please Select filter for search!',
        });
      }

      if (searchVal === "") {
        messageApi.open({
          type: 'warning',
          content: 'Please Enter Value for search!',
        });
      }

    }

  }

  const handleReset = () => {
    setSelectedFilter("Filter Attribute");
    setLastEvaluatedKey("");
    setTemplateArr([]);
    setCurrentPage(1);
    setTotalPages(1);
    setSearchVal("");
    setMinDate(getISOStringDate((subtractDays(new Date(), 30)).toString(), 'min'));
    setMaxDate(getISOStringDate((new Date()).toString(), 'max'));
    setSearchClick(false);
    setModalOpen(false);
    setTemplateListModalOpen(false);
    setResendModalOpen(false);
    setSearchModal(false);
    setSearchModalTxt("");
    setViewDataArr([]);
    setViewDataObj("");
    setMtemplateList([]);
    setResendClick(false);
    setSelectedTemplate({});
    setSelectedNotifType("email");
    setDateSelected(false);
    getNotifications("", getISOStringDate((subtractDays(new Date(), 30)).toString(), 'min'), getISOStringDate((new Date()).toString(), 'max'), token);
  }

  //Set Search field value in state
  const handleSearchChange = (val: any) => {
    setSearchVal(val.target.value.trim());
  }

  const dateChangeHandler = (dates: any, dateStrings: any) => {
    if (dateStrings.length > 1 && dates != null) {
      setDateSelected(true);
      setMinDate(dateStrings[0]);
      setMaxDate(dateStrings[1]);
    }
    else {
      setDateSelected(false);
    }
  }

  const handleTemplateNameClick = (tempObj: any) => {
    getTemplateLists(tempObj.templateName, token);
  }

  const getKeyValue = (obj: Record<string, any>, isKey: boolean): string | null => {
    if (obj && Object.keys(obj).length > 0) {
      const [[firstKey, firstValue]] = Object.entries(obj);
      return isKey ? firstKey : firstValue;
    } else {
      return "";
    }
  };


  const handleResendChange = (index: any, event: any) => {
    const newInputValues = [...viewDataArr];
    newInputValues.find(obj => Object.keys(obj).some(key => key === index))[index] = event;
    setViewDataArr(newInputValues);
  };

  const handleSendClick = () => {
    let dataObj = {};
    let finalObj = {};
    // loop elements of the array 
    for (let i = 0; i < viewDataArr.length; i++) {
      Object.assign(dataObj, viewDataArr[i]);
    }

    if (selectedTemplate?.notificationType === "sms") {
      finalObj = {
        "template_id": selectedTemplate?.templateName,
        "template_type": selectedTemplate?.notificationType.toLowerCase(),
        "version": selectedTemplate?.templateVersion,
        "phone_number": selectedTemplate?.recipient.split(','),
        "data": dataObj
      }
    } else {
      finalObj = {
        "template_id": selectedTemplate?.templateName,
        "template_type": selectedTemplate?.notificationType.toLowerCase(),
        "version": selectedTemplate?.templateVersion,
        "to": selectedTemplate?.recipient.split(','),
        "data": dataObj
      }
    }

    createResendNotification(finalObj, token);
    setResendModalOpen(false);
  }

  const { Text } = Typography;
  const { RangePicker } = DatePicker;

  let locale = {
    emptyText: (
      <Empty description={tableNoDataMsg} />
    )
  };

  const handleViewNotificationAndOptInOutClick = (clickType: string) => {
    setCurrentPage(1);
    if (clickType === "optInOut") {
      currentTab = 2;
      let updatedNotificationColumns: any[] = [...columns];
      updatedNotificationColumns.splice(columns.length - 2, 0, {
        title: 'Opt In/Out Status',
        dataIndex: 'notificationSubscriptionStatus',
        align: 'center',
        key: 'notificationSubscriptionStatus'
      });
      setFinalNotificationColumns(updatedNotificationColumns);
      let tempNotificationArr = templateArr.map((item: any) => {
        return {
          ...item,
          optArr: true
        }
      })
      setTemplateArr([]);
      setTemplateArr(tempNotificationArr);
    } else {
      currentTab = 1;
      setFinalNotificationColumns(columns);
    }
  }


  //handleViewNotificationAndOptInOutClick('viewNotifications');

  const showLoaderOnNavChange = () => {
    setIsNavigationChanged(true);
    setInterval(() => {
      setIsNavigationChanged(false);
    }, 1000);
  }

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={[
            {
              key: '1',
              label: 'View Notifications',
              onClick: () => {
                let tempNotificationArr = templateArr.map((item: any) => {
                  return {
                    ...item,
                    optArr: false
                  }
                });
                showLoaderOnNavChange();
                setTemplateArr([]);
                setTemplateArr(tempNotificationArr);
                handleViewNotificationAndOptInOutClick("viewNotifications");
              }
            },
            {
              key: '2',
              label: 'Opt In/ Opt Out Status',
              onClick: () => {
                showLoaderOnNavChange();
                handleViewNotificationAndOptInOutClick("optInOut");
              }
            }
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ display: "flex", padding: 0, background: colorBgContainer }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined rev='example' /> : <MenuFoldOutlined rev='example' />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <Row style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Col>
              <Text style={{ fontSize: "16px", fontWeight: "400", marginRight: "8px" }}>Filter By</Text>
              <Select
                placeholder="Filter Attribute"
                style={{ width: 200, margin: "8px" }}
                onChange={handleChange}
                options={items}
                value={selectedFilter}
              />

              {selectedFilter === "recipient" && (
                <Select
                  placeholder="Notification Type"
                  style={{ width: 200, margin: "8px" }}
                  onChange={handleChangeNotificationType}
                  options={itemsNotificationType}
                  defaultValue={'Email'}
                  value={selectedNotifType}
                />
              )}

              {(selectedFilter === "notification_id") ?
                (<NumericInput style={{ width: "12rem" }} value={searchVal} onChange={setSearchVal} />) :
                selectedFilter !== "timestamp" &&
                (<Input style={{ width: "12rem", margin: "8px" }} placeholder="" onChange={(v) => handleSearchChange(v)} value={searchVal} />)
              }
              {(selectedFilter === "recipient" || selectedFilter === "timestamp") && (
                <RangePicker style={{ width: "12rem", margin: "8px" }} format="YYYY-MM-DD" onChange={dateChangeHandler} />
              )}
            </Col>
            {/* OnSearch click resetting all the dasboard notification list to initial state and setting current page to 1 */}
            <Col><Button style={{ backgroundColor: "orange", marginLeft: "16px" }} type="primary" shape="round" size="middle" onClick={() => { handleSearch() }}>Search</Button></Col>

            <Col><Button style={{ backgroundColor: "orange", marginLeft: "16px" }} type="primary" shape="round" size="middle" onClick={() => { handleReset() }}>Reset</Button></Col>

          </Row>
        </Header>
        <Content
          style={{
            padding: 0,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Row key="dashboard-wrapper" className="dashboard-wrapper">
            {contextHolder}
            {contextHolderNotification}
            <Space direction="vertical" size="middle" style={{ display: 'flex', width: '100%' }}>
              <Spin spinning={loadingUI || isNavigationChanged}>
                <Row>
                  <Table
                    locale={locale}
                    dataSource={templateArr}
                    rowClassName={getRowClassName}
                    columns={finalNotificationColumns}
                    pagination={{ current: currentPage, pageSize: (lastEvaluatedKey !== null && lastEvaluatedKey !== "") ? 24 : 25, showSizeChanger: false }}
                    onChange={handlePagination}
                    scroll={{ y: 400 }} />
                </Row>

                {/* View Data Modal */}
                <Modal
                  title=""
                  style={{ top: 20 }}
                  open={modalOpen}
                  closable={false}
                  onOk={() => setModalOpen(false)}
                  onCancel={() => setModalOpen(false)}
                  footer={[
                    <Button style={{ backgroundColor: "orange", color: "white" }} key="back" onClick={() => setModalOpen(false)}>
                      Ok
                    </Button>
                  ]}
                >
                  <Row>
                    <Col span={24} style={{ maxHeight: "70%", minHeight: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {(viewDataObj && viewDataObj.length > 0) ? (<pre>{viewDataObj}</pre>) : loadingUI ? (<Spin spinning={loadingUI} ></Spin>) : (<Empty description={tableNoDataMsg} />)}
                    </Col>
                  </Row>
                </Modal>

                {/* Tempalte Notification Modal */}
                <Modal
                  title=""
                  style={{ top: 20 }}
                  width={1200}
                  open={templateListModalOpen}
                  closable={false}
                  onOk={() => setTemplateListModalOpen(false)}
                  onCancel={() => setTemplateListModalOpen(false)}
                  footer={[
                    <Button style={{ backgroundColor: "orange", color: "white" }} key="back" onClick={() => setTemplateListModalOpen(false)}>
                      Ok
                    </Button>
                  ]}
                >
                  <Row>
                    <Col span={24}>
                      <Table dataSource={mtemplateList} columns={templateListColumns} />
                    </Col>
                  </Row>
                </Modal>

                {/* Resend Modal */}
                <Modal
                  title=""
                  style={{ top: 20 }}
                  open={resendModalOpen}
                  closable={false}
                  onOk={() => setResendModalOpen(false)}
                  onCancel={() => setResendModalOpen(false)}
                  footer={[
                    <Button style={{ backgroundColor: "orange", color: "white" }} key="back" onClick={() => handleSendClick()}>
                      Send
                    </Button>,
                    <Button style={{ backgroundColor: "grey", color: "white" }} key="back" onClick={() => setResendModalOpen(false)}>
                      Cancel
                    </Button>
                  ]}
                >
                  {viewDataArr && viewDataArr.length > 0 ? (<Row gutter={[16, 16]}>
                    {viewDataArr.map((viewObj: any, index: number) => {
                      return (
                        <Col span={12} style={{ paddingRight: "12px" }}>
                          <div style={{ marginBottom: "16px" }}>
                            <Text>{getKeyValue(viewObj, true)}</Text>
                            <Input
                              style={{ marginTop: "8px" }}
                              placeholder="Notication ID"
                              value={`${getKeyValue(viewObj, false)}`}
                              onChange={(event) => { handleResendChange(getKeyValue(viewObj, true), event.target.value) }}
                            />
                          </div>
                        </Col>
                      )
                    })}
                  </Row>) :
                    <Row>
                      <Col span={24} style={{ maxHeight: "70%", minHeight: "300px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {loadingUI ? (<Spin spinning={loadingUI} ></Spin>) :
                          (<Empty description={tableNoDataMsg} />)
                        }
                      </Col>
                    </Row>
                  }
                </Modal>

                <Modal
                  title=""
                  centered
                  open={searchModal}
                  closable={false}
                  onOk={() => setSearchModal(false)}
                  footer={[
                    <Button style={{ backgroundColor: "orange", color: "white" }} key="back" onClick={() => setSearchModal(false)}>
                      Ok
                    </Button>
                  ]}
                >
                  <Row gutter={[16, 16]}>
                    <Col>
                      <Text>
                        {searchModalTxt === 'modal' ? (
                          <>
                            Notification has been sent successfully
                          </>
                        ) : (
                          <>
                            Notifications data is being fetched for the last <strong>30 days</strong>.<br /><br />
                            However, if you need data beyond <strong>30 days</strong>, select the <strong>Min</strong> and <strong>Max</strong> dates accordingly.
                          </>
                        )}
                      </Text>
                    </Col>
                  </Row>
                </Modal>
              </Spin>
            </Space>
          </Row>
        </Content>
      </Layout>
    </Layout>

  );
};
export default NewDashboard;
